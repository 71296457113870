<template>
  <div>
    <div class="nav-title">
      {{ $t("paymentManage") }}
    </div>

    <div class="search-query-container">
      <div class="search-query">
        <el-form :form="searchForm">
          <el-row :gutter="20">
            <el-col :lg="12" :md="24" :xs="24" :sm="24">
              <el-form-item :label="$t('tradeDateRange')">
                <search-date class="search-date" :data.sync="searchForm.searchDate" style="width: 100%;" />
              </el-form-item>
            </el-col>
            <el-col :lg="12" :md="24" :xs="24" :sm="24">
              <el-form-item :label="$t('tradeKind')">
                <el-select v-model="searchForm.tradeState" style="width: 100%;">
                  <el-option :label="$t('all')" value=""></el-option>
                  <el-option :label="$t('payment')" value="SUCCESS"></el-option>
                  <el-option :label="$t('refund')" value="REFUND"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :lg="12" :md="24" :xs="24" :sm="24">
              <el-form-item :label="$t('store')">
                <el-select
                  v-if="$settings.newStoreVersion === true"
                  v-model="searchForm.shopList"
                  :placeholder="$t('tradeStroeHint')"
                  v-bind:multiple="notAll"
                  @change="changeAll"
                  filterable
                  clearable
                  style="width: 100%;"
                >
                  <el-option :label="$t('all')" value="" changed></el-option>
                  <el-option v-for="item in shopList" :key="item.storeId" :label="item.storeName" :value="item.storeId">
                  </el-option>
                </el-select>
                <el-select
                  v-else
                  v-model="searchForm.shopList"
                  :placeholder="$t('tradeStroeHint')"
                  v-bind:multiple="notAll"
                  @change="changeAll"
                  filterable
                  clearable
                  style="width: 100%;"
                >
                  <el-option :label="$t('all')" value="" changed></el-option>
                  <el-option-group v-for="group in shopList" :key="group.label" :label="group.label">
                    <el-option
                      v-for="item in group.options"
                      :key="item.shopId"
                      :label="item.shopName"
                      :value="item.shopId"
                    >
                    </el-option>
                  </el-option-group>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :lg="12" :md="24" :xs="24" :sm="24">
              <el-form-item :label="$t('terminalNo')">
                <el-input clearable v-model="searchForm.deviceNo"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :lg="12" :md="24" :xs="24" :sm="24">
              <el-form-item :label="$t('orderNo')">
                <el-input clearable v-model="searchForm.orderNo" :placeholder="$t('tradNoHint')"></el-input>
              </el-form-item>
            </el-col>
            <el-col :lg="12" :md="24" :xs="24" :sm="24">
              <el-form-item :label="$t('paymentOptions')">
                <el-select
                  v-model="searchForm.tradeType"
                  multiple
                  filterable
                  clearable
                  style="width: 100%;"
                  ref="tradeType"
                  :placeholder="$t('payKindHint')"
                  @change="tradeTypeChange"
                >
                  <el-option v-for="item in tradeTypes" :key="item.category" :label="item.name" :value="item.category">
                    <div class="select-option-with-images">
                      <img class="option-image" :src="getPaymentLogos(item.name)" :alt="item.name" />
                      <span>{{ item.name }}</span>
                    </div>
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>

        <div class="search-btn">
          <el-button class="cancel" @click="resetSearchQuery">{{ $t("searchReset") }}</el-button>
          <el-button class="confirm" @click="getList(1)" :disabled="loading">{{ $t("search") }}</el-button>
        </div>
      </div>
      <div class="summary-container">
        <div class="summary-title">
          {{ $t("tradeDataByDateRange") }}
        </div>

        <div>
          <div class="summary-item">
            <div class="key">{{ $t("tradeCount") }}</div>
            <div class="value">{{ numFormat(total) }} {{ $t("passage") }}</div>
          </div>
          <div class="summary-item">
            <div class="key">{{ $t("payTotalCount") }}</div>
            <div class="value">{{ numFormat(totalPayCount) }} {{ $t("passage") }}</div>
          </div>
          <div class="summary-item">
            <div class="key">{{ $t("refundTotalCount") }}</div>
            <div class="value">{{ numFormat(totalRefundCount) }} {{ $t("passage") }}</div>
          </div>
          <div class="summary-item">
            <div class="key">{{ $t("totalAmount") }}</div>
            <div class="value">{{ moneyFormat(totalAmount) }}</div>
          </div>
          <div class="summary-item">
            <div class="key">{{ $t("payTotalAmount") }}</div>
            <div class="value">{{ moneyFormat(totalPayAmount) }}</div>
          </div>
          <div class="summary-item">
            <div class="key">{{ $t("refundTotalAount") }}</div>
            <div class="value">{{ moneyFormat(totalRefundAmount) }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="panel-sub-title">
      <div class="title">{{ total }} {{ $t("tradeCountPay") }}({{ beginCount }}~{{ endCount }})</div>

      <el-button
        class="cancel"
        :loading="btnLoading"
        :disabled="btnLoading"
        @click="downloadClick"
        icon="el-icon-download"
        v-permission="'Record.RecordDownload'"
      >
        {{ $t("recordDownload") }}
      </el-button>
    </div>

    <div class="result-table">
      <el-table :data="tradeData" :row-class-name="rowClassName" v-loading="loading" @row-click="toDetail">
        <el-table-column prop="endTime" :label="$t('dateTime')" min-width="220">
          <template slot-scope="scope">
            <i class="el-icon-time"></i>
            <span style="margin-left: 5px">{{ dateFormat(scope.row.endTime) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="outTradeNo" :label="$t('outTradeNo')" min-width="200"></el-table-column>
        <el-table-column prop="orderNo" :label="$t('orderNo')" min-width="200"></el-table-column>
        <el-table-column prop="shopName" :label="$t('store')" min-width="150"></el-table-column>
        <el-table-column prop="deviceNo" :label="$t('terminalNo')" width="150"></el-table-column>
        <el-table-column prop="tradeState" :label="$t('tradeKind')" width="100">
          <template slot-scope="scope">
            {{ scope.row.tradeState === "REFUND" ? $t("refund") : $t("payment") }}
          </template>
        </el-table-column>
        <el-table-column prop="tradeTypeDisplayName" :label="$t('tradePayKind')" width="220">
          <template slot-scope="scope">
            <div class="image-text-container">
              <img :src="getPaymentLogos(scope.row.tradeTypeDisplayName)" :alt="scope.row.tradeTypeDisplayName" />
              <span>{{ scope.row.tradeTypeDisplayName }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="orderAmount" :label="$t('cost')" min-width="150">
          <template slot-scope="scope">{{ moneyFormat(Number(scope.row.tradeAmount)) }} </template>
        </el-table-column>
        <el-table-column fixed="right" width="150">
          <template slot-scope="scope">
            <div style="text-align: center;">
              <el-button size="mini" type="text" @click.native.stop="toDetail(scope.row)">
                {{ $t("detail") }}
                <i class="el-icon-arrow-right"></i>
              </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <el-pagination
      background
      layout=" prev, pager, next, sizes, total"
      :total="total"
      :page-size="currentPageSize"
      :page-sizes="[10, 15, 20]"
      :current-page="currentPage"
      @current-change="currentChange"
      @size-change="sizeChange"
      class="common-pagination"
    >
    </el-pagination>

    <side-layer :show-side-layer.sync="detailDrawer">
      <div v-loading="detailLoading">
        <div class="setting-nav-title">{{ $t("paymentInfo") }}</div>
        <div class="name-value" style="margin-bottom: 20px;">
          <table cellspacing="0" cellpadding="0" border="0">
            <tr>
              <td class="name">{{ $t("payDateTime") }}</td>
              <td class="value">
                {{ dateFormat(detailInfo.endTime) }}
              </td>
            </tr>
            <tr>
              <td class="name">
                {{ $t("enterpriseCode") }}
              </td>
              <td class="value">
                {{ detailInfo.entId }}
              </td>
            </tr>

            <tr>
              <td class="name">{{ $t("storeCode") }}</td>
              <td class="value">
                {{ detailInfo.shopId }}
              </td>
            </tr>
            <tr>
              <td class="name">{{ $t("storeName") }}</td>
              <td class="value">
                {{ detailInfo.shopDisplay }}
              </td>
            </tr>
            <tr>
              <td class="name">{{ $t("terminalNo") }}</td>
              <td class="value">
                {{ detailInfo.deviceNo }}
              </td>
            </tr>
            <tr>
              <td class="name">
                {{ $t("tradeKind") }}
              </td>
              <td class="value">
                {{ $t("payment") }}
              </td>
            </tr>
            <tr>
              <td class="name">
                {{ $t("outTradeNo") }}
              </td>
              <td class="value">
                {{ detailInfo.outTradeNo }}
              </td>
            </tr>
            <tr>
              <td class="name">
                {{ $t("tradePayKind") }}
              </td>
              <td class="value image-text-container">
                <img :src="getPaymentLogos(detailInfo.tradeTypeDisplayName)" :alt="detailInfo.tradeTypeDisplayName" />
                <span>{{ detailInfo.tradeTypeDisplayName }} </span>
              </td>
            </tr>
            <tr>
              <td class="name">
                {{ $t("payAmount") }}
              </td>
              <td class="value">
                {{ moneyFormat(Number(detailInfo.tradeAmount)) }}
              </td>
            </tr>
            <tr>
              <td class="name">{{ $t("orderNo") }}</td>
              <td class="value">
                {{ detailInfo.orderNo }}
              </td>
            </tr>
            <tr v-if="detailInfo.combinationPayment">
              <td class="name">{{ $t("pointCard") }}</td>
              <td class="value">
                {{ detailInfo.pointCardNo }}
              </td>
            </tr>
          </table>
        </div>

        <div class="setting-nav-title" v-if="detailInfo.refundRecordList && detailInfo.refundRecordList.length > 0">
          {{ $t("tradeRefundInfo") }}
        </div>

        <div
          class="name-value"
          v-for="item in detailInfo.refundRecordList"
          :key="item.recordId"
          style="margin-bottom: 20px;"
        >
          <table cellspacing="0" cellpadding="0" border="0">
            <tr>
              <td class="name">
                {{ $t("refundDate") }}
              </td>
              <td class="value">
                {{ dateFormat(item.beginTime) }}
              </td>
            </tr>

            <tr>
              <td class="name">
                {{ $t("terminalNo") }}
              </td>
              <td class="value">
                {{ item.deviceNo }}
              </td>
            </tr>

            <tr>
              <td class="name">
                {{ $t("refundNo") }}
              </td>
              <td class="value">
                {{ item.refundNo }}
              </td>
            </tr>

            <tr>
              <td class="name">{{ $t("tradeKind") }}</td>
              <td class="value">
                {{ $t("refund") }}
              </td>
            </tr>
            <tr>
              <td class="name">
                {{ $t("tradePayKind") }}
              </td>
              <td class="value image-text-container">
                <img :src="getPaymentLogos(detailInfo.tradeTypeDisplayName)" :alt="detailInfo.tradeTypeDisplayName" />
                <span>{{ item.tradeTypeDisplayName }} </span>
              </td>
            </tr>
            <tr>
              <td class="name">
                {{ $t("cashCount") }}
              </td>
              <td class="value">{{ moneyFormat(item.refundAmount) }}</td>
            </tr>
          </table>
        </div>
      </div>
    </side-layer>
    <modal-dialog :show-dialog.sync="downloadDialog" :dialog-title="$t('dataDownload')" :show-footer="false">
      <div class="download-type-container">
        <div class="download-type-item" @click="downloadTradData(3)">
          <img src="~@/assets/download_type_icon/csv.svg" alt="" />
          <span>CSV</span>
        </div>
        <div class="download-type-item" @click="downloadTradData(0)">
          <img src="~@/assets/download_type_icon/excel.svg" alt="" />
          <span>Excel</span>
        </div>

        <div class="download-type-item" @click="downloadTradData(1)">
          <img src="~@/assets/download_type_icon/pdf.svg" alt="" />
          <span>PDF</span>
        </div>

        <div class="download-type-item" @click="downloadTradData(2)">
          <img src="~@/assets/download_type_icon/zip.svg" alt="" />
          <span>ZIP</span>
        </div>
      </div>
    </modal-dialog>
  </div>
</template>

<script>
import pagination from "@/mixins/pagination";
import getPaymentLogos from "@/mixins/paymentLogos";

import { tradeTypes, downloadPayFile, payRecordList, payRecordDetail } from "api/trade";
import { shopList, storeList } from "api/shop";
import { isArray } from "lodash";
import { moneyFormat, numFormat, pointFormat } from "utils";
import { executeDownload } from "utils/download";
import { dateFormat, diffMonths } from "utils/date";
export default {
  mixins: [pagination, getPaymentLogos],

  data() {
    return {
      tradeData: [],
      spanData: [],
      loading: false,
      btnLoading: false,
      totalPayAmount: 0,
      totalRefundAmount: 0,
      shopList: [],
      tradeTypes: [],
      searchForm: {
        searchDate: [
          this.moment()
            .startOf("day")
            .format("YYYY/MM/DD HH:mm:ss"),
          this.moment()
            .endOf("day")
            .format("YYYY/MM/DD HH:mm:ss")
        ],
        shopList: "",
        tradeType: [],
        tradeState: "",
        deviceNo: "",
        tradeRefvNo: "",
        orderNo: ""
      },
      downloadDialog: false,
      detailDrawer: false,
      detailLoading: false,
      detailInfo: {},
      refundDialog: false,
      canRefundAmount: 0,
      refundAmount: 0,
      refundType: "all",
      refundAmountPrecision: 0,
      canPartRefund: true,
      canEditRefundFee: false,
      refundLoading: false,
      notAll: false,
      beginCount: 0,
      endCount: 0,
      currentHoverRowId: [],
      totalAmount: "",
      totalPayCount: "",
      totalRefundCount: ""
    };
  },

  methods: {
    dateFormat,
    numFormat,
    pointFormat,
    diffMonths,
    moneyFormat,

    tradeTypeChange() {
      let isMobile = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );

      this.$nextTick(() => {
        if (isMobile) this.$refs["tradeType"].blur();
      });
    },

    changeAll(value) {
      if (!value[value.length - 1]) {
        this.searchForm.shopList = "";
        this.notAll = false;
      } else {
        if (isArray(value)) {
          this.searchForm.shopList = value;
        } else {
          this.searchForm.shopList = [value];
        }

        this.notAll = true;
      }
    },

    getList(pageIndex = 1, pageSize = this.currentPageSize) {
      let that = this;
      let beginTime = this.searchForm.searchDate[0];
      let endTime = this.searchForm.searchDate[1];
      this.currentPage = pageIndex;
      if (diffMonths(endTime, beginTime) > 2) {
        this.$message.warning(this.$t("tradeSearchPeriodLimit"));
      } else {
        this.loading = true;
        let data = JSON.parse(JSON.stringify(that.searchForm));
        delete data.searchDate;
        if (!isArray(data.shopList)) {
          data.shopList = [];
        }
        payRecordList({
          pageIndex,
          pageSize,
          beginTime,
          endTime,
          ...data
        }).then(response => {
          this.loading = false;
          if (response.statusCode === 200 && response.resultStatus === "Success") {
            this.tradeData = response.result.records;
            this.total = response.result.totalCount;
            this.totalRefundAmount = response.result.totalRefundAmount;
            this.totalPayAmount = response.result.totalPayAmount;

            //dpoint
            this.totalAmount = response.result.totalAmount;
            this.totalPayCount = response.result.totalPayCount;
            this.totalRefundCount = response.result.totalRefundCount;
            //dpointend
            if (response.result.records.length) {
              that.beginCount = (pageIndex - 1) * pageSize + 1;
              that.endCount = that.beginCount + response.result.records.length - 1;
            }
          } else {
            this.tradeData = [];
            this.total = 0;
            this.totalRefundAmount = 0;
            this.totalPayAmount = 0;
            //dpoint
            this.totalAmount = 0;
            this.totalPayCount = 0;
            this.totalRefundCount = 0;
            //dpointend
            that.beginCount = 0;
            that.endCount = 0;
          }
        });
      }
    },
    resetSearchQuery() {
      this.searchForm = {
        searchDate: [
          this.moment()
            .startOf("day")
            .format("YYYY/MM/DD HH:mm:ss"),
          this.moment()
            .endOf("day")
            .format("YYYY/MM/DD HH:mm:ss")
        ],
        shopList: [],
        tradeType: [],
        tradeState: "",
        deviceNo: "",
        tradeRefvNo: ""
      };
    },

    toDetail(row) {
      this.detailDrawer = true;
      this.detailLoading = true;
      this.detailInfo = {};
      let { orderNo, outTradeNo, tradeType } = row;
      if (row.isCreditCard) {
        outTradeNo = orderNo;
      }
      payRecordDetail({ outTradeNo, tradeType }).then(response => {
        this.detailLoading = false;
        if (response.statusCode === 200 && response.resultStatus === "Success") {
          this.detailInfo = response.result;
          if (this.detailInfo.combinationPayment) {
            this.canRefundAmount = Number(this.detailInfo.combinationPaymentTotalAmount);
          } else {
            this.canRefundAmount = Number(this.detailInfo.orderAmount);
          }
          if (isArray(this.detailInfo.refundRecordList)) {
            let allAmount = 0;
            this.detailInfo.refundRecordList.forEach(item => {
              allAmount += Number(item.refundAmount);
            });
            if (this.detailInfo.combinationPayment) {
              this.canRefundAmount = Number(this.detailInfo.combinationPaymentTotalAmount) - Number(allAmount);
            } else {
              this.canRefundAmount = Number(this.detailInfo.orderAmount) - Number(allAmount);
            }
          }
        }
      });
    },
    rowClassName({ row }) {
      let className = "";

      if (row.tradeState === "REFUND") className += "row-refund-color";

      this.currentHoverRowId.some(item => item.groupId === row.groupId) ? (className += " group-hover-row") : "";

      return className;
    },

    getShopList() {
      if (this.$settings.newStoreVersion === true) {
        storeList().then(response => {
          if (response.statusCode === 200 && response.resultStatus === "Success") {
            this.shopList = response.result;
          }
        });
      } else {
        shopList().then(response => {
          if (response.statusCode === 200 && response.resultStatus === "Success") {
            this.shopList = [
              {
                label: this.$t("shopOffline"),
                options: []
              },
              {
                label: this.$t("shopOnline"),
                options: []
              }
            ];

            if (response.result.length > 0) {
              response.result.forEach(item => {
                if (item.isOnline) {
                  this.shopList[1].options.push(item);
                } else {
                  this.shopList[0].options.push(item);
                }
              });
            }
          }
        });
      }
    },

    getTradeTypes() {
      tradeTypes().then(response => {
        if (response.statusCode === 200 && response.resultStatus === "Success") {
          this.tradeTypes = response.result;
          this.getList();
        }
      });
    },
    downloadClick() {
      this.downloadDialog = true;
    },
    downloadTradData(fileType) {
      this.downloadDialog = false;
      this.btnLoading = true;
      downloadPayFile({
        BeginTime: this.searchForm.searchDate[0],
        EndTime: this.searchForm.searchDate[1],
        ShopList: this.searchForm.shopList,
        TradeType: this.searchForm.tradeType,
        TradeState: this.searchForm.tradeState,
        DeviceNo: this.searchForm.deviceNo,
        orderNo: this.searchForm.orderNo,
        fileFormat: fileType
      }).then(response => {
        if (response.data) {
          executeDownload(response);
          this.btnLoading = false;
          this.$message.success(this.$t("success"));
        } else {
          this.btnLoading = false;
        }
      });
    }
  },
  mounted() {
    this.getShopList();
    this.getTradeTypes();
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/mixin.scss";
@import "~@/styles/variables.scss";

.select-option-with-images {
  @include flex(flex-start);
  .option-image {
    height: 36px;
    margin-right: 10px;
  }
}
</style>
